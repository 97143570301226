<template>
  <div>
    <div
      v-if="hasSchedule"
      class="grid grid-cols-1 gap-4 lg:grid-cols-2"
    >
      <recording-card
        v-for="meeting in rows"
        :key="meeting.id"
        :meeting="meeting"
      />
    </div>
    <div v-else-if="loaded">
      <a-alert type="info">
        There are no recordings to display
      </a-alert>
    </div>
  </div>
</template>

<script>
import { filter, isEmpty } from '@/utils/lodash';
import { mapState } from 'vuex';
import RecordingCard from '@/components/meetings/card/recording.vue';
export default {
  components: {
    RecordingCard
  },
  computed: {
    ...mapState('schedule', ['schedule', 'loaded']),
    rows() {
      return filter(this.schedule, r => !isEmpty(r.video));
    },
    hasSchedule() {
      return !isEmpty(this.rows);
    }
  }
};
</script>