<template>
  <div class="space-y-4">
    <a-heading>Show Schedule</a-heading>

    <div
      v-if="loaded"
      class="flex flex-col lg:flex-row-reverse"
    >
      <div class="w-full lg:w-4/12 lg:px-4">
        <filters
          :items="filterItems"
          :active="activeType"
          @change="onFilterChange"
        />
      </div>
      <div class="w-full lg:w-8/12 space-y-4">
        <dates-tabs
          v-model="selectedDate"
          :dates="scheduleDates"
          :selected="selectedDate"
          @change="onDateChange"
          @input="onDateModelChange"
        />
        <div
          v-if="hasRows"
          class="space-y-4"
        >
          <meeting-card
            v-for="row in rows"
            :key="row.id"
            :meeting="row"
          />
        </div>
        <div v-else-if="loaded">
          <a-alert type="info">
            Attendees may add meetings to their expo schedules on the Show Schedule page.
          </a-alert>
        </div>
      </div>
    </div>
    <div v-else>
      <loading-cards />
    </div>
  </div>
</template>

<script>
import { error } from '@/utils/helpers';
import { filter, isEmpty } from '@/utils/lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import MeetingCard from '@/components/meetings/card/card.vue';
import DatesTabs from '@/components/ui/dates/dates-tabs.vue';
import Filters from '@/components/schedules/filters/type.vue';
import LoadingCards from '@/components/conferences/cards/loading.vue';
import { format } from '@/utils/dates';
export default {
  components: {
    Filters,
    DatesTabs,
    MeetingCard,
    LoadingCards
  },
  data: () => ({
    selectedDate: '',
    selectedType: null
  }),
  computed: {
    ...mapState('schedule', ['schedule']),
    ...mapGetters('schedule', [
      'loaded',
      'scheduleDates',
      'total',
      'countByType'
    ]),
    rows() {
      let rows = this.schedule;

      if (!isEmpty(this.selectedDate)) {
        rows = filter(
          rows,
          row => format(row.slot.start, 'YYYY-MM-DD') === this.selectedDate
        );
      }

      if (!isEmpty(this.selectedType)) {
        rows = filter(rows, row => row.type.key === this.selectedType);
      }

      return rows;
    },
    hasRows() {
      return !isEmpty(this.rows);
    },
    filterItems() {
      return [
        { key: 'all', label: 'All', icon: 'home', badge: this.total },
        {
          key: 'seminar',
          label: 'Seminar',
          icon: 'book-open',
          badge: this.countByType('seminar')
        },
        {
          key: 'presentation',
          label: 'Presentation',
          icon: 'user-chart',
          badge: this.countByType('presentation')
        },
        {
          key: 'event',
          label: 'Event',
          icon: 'calendar-week',
          badge: this.countByType('event')
        },
        {
          key: 'stream',
          label: 'Stream',
          icon: 'signal-stream',
          badge: this.countByType('stream')
        }
      ];
    },
    activeType() {
      return !isEmpty(this.selectedType) ? this.selectedType : 'all';
    }
  },
  async created() {
    try {
      await this.all();
    } catch (e) {
      this.$notify('danger', { html: error(e) });
    }
  },
  methods: {
    ...mapActions('schedule', ['all']),
    onDateChange(date) {
      if (this.selectedDate === date) {
        this.selectedDate = '';
      } else {
        this.selectedDate = date;
      }
    },
    onDateModelChange(event) {
      this.selectedDate = event.target.value;
    },
    onFilterChange(item) {
      if (item.key === 'all') {
        this.selectedType = null;
      } else {
        this.selectedType = item.key;
      }
    }
  }
};
</script>