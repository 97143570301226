<template>
  <div
    v-if="isIframe"
    v-html="iframeVideo"
  />
  <div
    v-else
    class="w-full"
  >
    <iframe
      v-if="isService"
      :width="width"
      :height="height"
      :src="row.video"
      frameborder="0"
      autoplay="false"
      allowfullscreen
    />
    <video
      v-else
      controls
      class="w-full"
    >
      <source :src="row.video">
    </video>
  </div>
</template>

<script>
import { includes } from '@/utils/lodash';
export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: '400'
    }
  },
  computed: {
    isIframe() {
      return includes(this.row.video, 'iframe');
    },
    isService() {
      return (
        includes(this.row.video, 'youtube') || includes(this.row.video, 'vimeo')
      );
    },
    iframeVideo() {
      let code = this.row.video.replace(
        /(width\s*=\s*["'](.*?)["'])/g,
        `width='${this.width}'`
      );

      if (this.isIframe) {
        code = code.replace(
          /(height\s*=\s*["'](.*?)["'])/g,
          `height='${this.height}'`
        );
      }

      return code;
    }
  }
};
</script>