<template>
  <div class="w-full my-2">
    <a
      class="button button--primary button--size--sm cursor-pointer"
      :href="url"
      target="_blank"
    >
      Export Schedule
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('auth', ['token']),
    ...mapGetters('conferences', ['current']),
    timezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    url() {
      return (
        process.env.VUE_APP_API_ROUTE +
        `/conferences/${this.current}/user/schedule/export?timezone=${this.timezone}&token=${this.token}`
      );
    }
  }
};
</script>