<template>
  <div>
    <div class="sm:hidden">
      <label
        for="tabs"
        class="sr-only"
      >Select a date</label>
      <select
        id="tabs"
        name="tabs"
        class="block w-full focus:ring-primary focus:border-primary border-gray-700 rounded-md p-2"
        v-bind="$attrs"
      >
        <option />
        <option
          v-for="date in dates"
          :key="date"
          :value="date"
          :selected="date === selected"
        >
          {{ format(date, 'dddd, MMMM D') }}
        </option>
      </select>
    </div>
    <div class="hidden sm:block">
      <div class="border-b border-gray-300">
        <nav
          class="-mb-px flex"
          aria-label="Tabs"
        >
          <a
            v-for="date in dates"
            :key="date"
            v-tooltip.bottom="date === selected ? 'Click to remove the date filter' : 'Click to filter by date'"
            class="border-transparent text-black hover:text-primary hover:border-primary w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm cursor-pointer"
            :class="{'border-primary text-primary': date === selected}"
            @click="onDateChange(date)"
          >
            {{ format(date, 'dddd, MMMM D') }}
          </a>
        </nav>
      </div>
    </div>
    <a-alert
      v-if="selected"
      type="info"
    >
      <div class="flex justify-between">
        <div>Displaying results for {{ format(selected, 'dddd, MMMM D') }} only.</div>
        <div>
          <a-button
            size="xs"
            variant="primary"
            @click="onClear"
          >
            Remove Filter
          </a-button>
        </div>
      </div>
    </a-alert>
  </div>
</template>

<script>
import { format } from '@/utils/dates';
export default {
  props: {
    dates: {
      type: Array,
      required: true
    },
    selected: {
      type: String,
      required: true
    }
  },
  emits: ['change'],
  methods: {
    format,
    onDateChange(date) {
      this.$emit('change', date);
    },
    onClear() {
      this.$emit('change', '');
    }
  }
};
</script>