<template>
  <a-card :title="meeting.title">
    <embed-video :row="meeting" />
  </a-card>
</template>

<script>
import EmbedVideo from '@/components/ui/embed/video.vue';
export default {
  components: {
    EmbedVideo
  },
  props: {
    meeting: {
      type: Object,
      required: true
    }
  }
};
</script>