<template>
  <div class="relative lg:sticky lg:top-2 mb-4">
    <a-navigation-group
      :items="items"
      :active="active"
      @click="onClick"
    />
    <export-button v-if="showExport" />
  </div>
</template>

<script>
import ExportButton from '@/components/schedules/my-schedule/export-button.vue';
export default {
  components: {
    ExportButton
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    active: {
      type: String,
      required: true
    },
    showExport: {
      type: Boolean,
      required: false
    }
  },
  emits: ['change'],
  methods: {
    onClick(item) {
      this.$emit('change', item);
    }
  }
};
</script>