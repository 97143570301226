<template>
  <div class="flex-1 max-w-4xl mx-auto flex flex-col md:px-8 xl:px-0">
    <div class="flex-1 relative overflow-y-auto focus:outline-none">
      <div class="px-4 sm:px-6 md:px-0">
        <a-card
          v-if="meetingRow"
          :title="meetingRow.title"
        >
          <embed-video 
            v-if="video" 
            :row="meetingRow"
            height="600px"
          />
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from '@/utils/lodash';
import EmbedVideo from '@/components/ui/embed/video.vue';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    EmbedVideo
  },
  props: {
    meeting: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    ...mapState('schedule', ['schedule']),
    ...mapGetters('schedule', ['find']),
    meetingRow() {
      return this.find(this.meeting);
    },
    video() {
      return get(this.meetingRow, 'video');
    }
  }
};
</script>